#power {
  min-height: 100vh;
}

[class^="bi-"]{
  padding-right: 0.2rem;
}

.btn [class^="bi-"]{
  padding-right: 0.6rem;
}

.filters .btn {
  padding: 1rem 0.75rem !important;
}

.nav-link {
  color: #154448 !important;
}